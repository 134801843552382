const AuthEndPoints = {
  signin: () => '/auth/signin',
  signup: () => '/auth/signup',

  signout: () => '/auth/signout',
  refreshTokens: () => '/auth/refresh',
  exists: () => '/auth/exists',

  permissions: () => '/auth/permissions',
  forgotPassword: () => '/auth/forgot-password',
  resetPassword: () => '/auth/reset-password',
  updatePassword: () => '/auth/update-password',
  retrieveApiKey: () => '/auth/api_key',
  generateApiKey: () => '/auth/api_key/generate',
  regenerateApiKey: () => '/auth/api_key/regenerate',
  revokeApiKey: () => '/auth/api_key/revoke',
  tokenEmailVerification: () => '/auth/email-token-verification',
  sendSignupConfirmation: () => '/auth/send-signup-confirmation',

  // Buyers
  signupBuyerBusiness: () => '/buyers/signup-business',
  signupBuyerUser: () => '/buyers/signup-user',
};

const PortalEndPoints = {
  list: () => '/portals',
  get: (id: string) => `/portals/${id}`,
  bySlug: (slug: string) => `/portals/slug/${slug}`,
  themeSetings: (id: string) => `/portals/${id}/theme-settings`,
};

const BuyerEndPoints = {
  list: () => '/buyers',
  get: (id: string) => `/buyers/${id}`,
  getAccountManager: () => `/buyers/account-manager`,
  getBuyersByMerchantId: (merchantId: string) => `/buyers/merchant/buyers/${merchantId}`,
  getBuyerMerchantByBusinessId: (businessId: string) => `/buyers/merchant/${businessId}`,
  getBuyerBusinessByBusinessId: (businessId: string) => `/buyers/business/${businessId}`,
};

const MerchantEndPoints = {
  list: () => '/merchants',
  get: (id: string) => `/merchants/${id}`,
};

const UserEndPoints = {
  get: (id: string) => `/users/${id}`,
  list: () => '/users',
};

const MyAccountEndPoints = {
  get: () => `/myaccount`,
  permissions: () => '/myaccount/permissions',
  funds: () => '/myaccount/funds',
};

export const MsApiEndPoints = {
  auth: AuthEndPoints,
  portals: PortalEndPoints,
  buyers: BuyerEndPoints,
  merchants: MerchantEndPoints,
  users: UserEndPoints,
  myaccount: MyAccountEndPoints,
};
